//assets/plugins/pace/pace-theme-flash.css
@import "theme/assets/plugins/pace/pace-theme-flash.css";
@import "theme/assets/plugins/bootstrapv3/css/bootstrap.min.css";
@import "theme/assets/plugins/bootstrapv3/css/bootstrap-theme.min.css";
@import "theme/assets/plugins/font-awesome/css/font-awesome.css";
@import "theme/assets/plugins/animate.min.css";
@import "theme/assets/plugins/jquery-scrollbar/jquery.scrollbar.css";

@import "theme/webarch/css/webarch.css";

.page-content {
  min-height: 1000px;
}

/*select*/
.ac-selected {
  i {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.react-select__control {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  align-items: center;

  border-radius: 0;
  min-width: 300px;
  width: 100%;
  height: 40px;
  margin: 0;
  border: none;

  &:focus {
    border: none !important;
  }
}


// side bar menu
.side-bar-menu {
  .menu-item {
    display: flex;
    cursor: hand;
    &:hover {
      background: #1b1e24 !important;
    }

    a.main-url {

      .add-url {
        display: block;
        align-self: flex-end;
        //justify-self: space-around;
        //justify-self: flex-end;
      }
    }

  }
}
